import React from 'react'
import galleryy from '../Assets/galleryy.png'
import Green from '../Assets/Green Mode.png'

const Gallery = () => {
  return (
  <>


<div class="flex justify-center mt-14">
          <button class="inline-flex text-white bg-green-900 border-0 py-2 px-10 focus:outline-none hover:bg- rounded-lg text-lg transition-all duration-300">
          Collect
          </button>
          <button class="inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-10 focus:outline-none hover:bg-gray-400 rounded-lg text-lg">
          Gallery
          </button>
        </div>

        <div class="container mx-auto flex px-5 py-20 items-center justify-center flex-col">
  <img
    class="lg:w-1/3 md:w-1/2 w-2/3 mb-10 object-cover object-center rounded"
    alt="hero"
   src={galleryy} ></img>
 
</div>


{/* Gallery */}


<section class="text-gray-600 body-font">
<div class="container px-20 py-24 mx-auto">
    <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
      

      <div class="p-4 md:w-1/8 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
    
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/8 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
    
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/8 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
    
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/8 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
    
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/8 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
    
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>
      
      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

      <div class="p-4 md:w-1/7 flex flex-col text-center items-center">
        <div class="w-20 h-20 inline-flex items-center justify-center rounded-full  text-indigo-500 mb-5 flex-shrink-0">
        <img src={Green} alt="najgjh"></img>
        </div>
      </div>

    </div>
  </div>
</section>
  </>
  )
}

export default Gallery